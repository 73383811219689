import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './culture.module.scss';

const Culture = ({ intl: { formatMessage } }) => {
  return (
    <section className={styles.container_culture}>
      <div className={styles.container_culture__content_highlight}>
        <StaticImage
          src="../../../images/aboutUs/arrow_1.webp"
          alt="arrow_1"
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={["auto", "webp", "avif"]}
        />
      </div>
      <h2 className="title">{formatMessage({ id: 'aboutUsCultureTitle' })}</h2>
      <h6 className={styles.container_culture__subtitle}>
        {formatMessage({ id: 'aboutUsCultureSubtitle' })}
      </h6>
      <div className={styles.container_culture__container_cards}>
        <div className={styles.container_culture__card}>
          <div className={styles.container_culture__content_img}>
            <StaticImage
              src="../../../images/aboutUs/dart.webp"
              alt="dart"
              className={styles.container_culture__content_img__img}
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div>
            <p className={styles.container_culture__title}>
              {formatMessage({ id: 'aboutUsCultureCard1Title' })}
            </p>
            <small className={styles.container_culture__text}>
              {formatMessage({ id: 'aboutUsCultureCard1Content' })}
            </small>
          </div>
        </div>
        <StaticImage
          src="../../../images/aboutUs/arrow_follow.webp"
          className={styles.container_culture__follow}
          alt="arrow_follow"
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={["auto", "webp", "avif"]}
        />
        <div className={styles.container_culture__card}>
          <div className={styles.container_culture__content_img}>
            <StaticImage
              src="../../../images/aboutUs/eye.webp"
              alt="eye"
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
              className={styles.container_culture__content_img__img}
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div>
            <p className={styles.container_culture__title}>
              {formatMessage({ id: 'aboutUsCultureCard2Title' })}
            </p>
            <small className={styles.container_culture__text}>
              {formatMessage({ id: 'aboutUsCultureCard2Content' })}
            </small>
          </div>
        </div>
        <StaticImage
          className={styles.container_culture__follow}
          src="../../../images/aboutUs/arrow_follow.webp"
          alt="arrow_follow"
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={["auto", "webp", "avif"]}
        />
        <div className={styles.container_culture__card}>
          <div className={styles.container_culture__content_img}>
            <StaticImage
              src="../../../images/aboutUs/hand.webp"
              alt="hand"
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
              className={styles.container_culture__content_img__img}
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div>
            <p className={styles.container_culture__title}>
              {formatMessage({ id: 'aboutUsCultureCard3Title' })}
            </p>
            <small className={styles.container_culture__text}>
              {formatMessage({ id: 'aboutUsCultureCard3Content' })}
            </small>
          </div>
        </div>
      </div>
    </section>
  );
};

Culture.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Culture);
